$(document).ready(function() {
    // "use strict";
    //     if($('.attribute_container'.length > 0)){
    //         // push all checked elements into here
    //         var activeTags = [];
    //
    //         // check which input boxes are checked
    //         $(".modifypersonalattributeinfo_input_container_checkbox").find("input:checked").next('label').each(function (i, obj) {
    //             // push object text into activeTags
    //             activeTags.push($(obj).text());
    //         });
    //
    //         // log results
    //         console.log(activeTags);
    //
    //     }

    fontsize();
    initFontSize();
});

// fuctions
function getPageID(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(document.getElementsByTagName("form")[0].getAttribute("action"));

    if (results == null)
        return "";
    else
        return results[1];
}

$(document).ajaxStop(function() {
    var articles = $(".newsoverview > article.regular");
    for(var i = 0; i < articles.length; i+=3) {
        articles.slice(i, i+3).wrapAll("<div class='row'></div>");
    }

    // hide date on this specific page
    var pageID = getPageID('id');
    if (pageID === '5281' && $('#Block_PagesSmall').length > 0) {
        $('#Block_PagesSmall article time').css("display", "none");
    }

});

function fontsize() {
    var fonts = $("<div id='fontSize'><span class='js-font' data-size='small' style='font-size:12px;'>a</span><span class='js-font' data-size='regular' style='font-size:16px;'>a</span><span class='js-font' data-size='large' style='font-size:32px;'>a</span></div>");
    $(fonts).prependTo(".right");

    $('.js-font').on('click', function() {
        event.preventDefault();
        $('body').attr('class', '').addClass($(this).data('size'));
        // re-calculate height of blocks
        $(document).foundation('equalizer','reflow');

        // set setting in localstorage, and apply changes directly without refresh
        var current = $('body').attr('class');
        localStorage.setItem("fontSize", current);
    });
}

function initFontSize() {
    switch(localStorage.fontSize) {
        case "small":
            console.log('initfontsize: small font chosen');
            $("body").addClass("small");
            break;

        case "regular":
            console.log('initfontsize: regular font chosen');
            $("body").addClass("regular");
            break;

        case "large":
            console.log('initfontsize: large font chosen');
            $("body").addClass("large");
            break;
    }
}

